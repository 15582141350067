<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="pa-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-4'"
        :dark="$vuetify.theme.dark"
        class="pt-1"
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">My Account</h6>
          <slot name="userDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <div class="pa-5 mt-10">
      <div class="d-flex align-center mb-6">
        <v-avatar
          color="background darken-1 rounded-lg"
          class="mr-4"
          rounded
          size="60"
        >
          <span class="primary--text text--lighten-2 text-h5 font-weight-bold">{{ me.fullName.split(" ").map((n)=>n[0]).join("") }}</span>
        </v-avatar>
        <div>
          <p class="font-weight-medium ma-0">{{ me.fullName }}</p>
          <p class="body-2 text--disabled mb-2">
            {{ me.email }}
          </p>
        </div>
      </div>

      <v-list>
        <v-list-item two-line class="pa-0">
          <v-list-item-content>
            <v-list-item-title> Role </v-list-item-title>

            <v-list-item-subtitle v-if="me.role === 'Superuser'">
              Super User
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="me.role === 'Client Admin'">
              Manager
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="me.role === 'Client User'">
              User
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line class="pa-0">
          <v-list-item-content>
            <v-list-item-title> Organisation </v-list-item-title>
            <v-list-item-subtitle>
              {{ me.client ? me.client.name : "N/A" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="me.provider === 'internal'"
          two-line
          class="pa-0"
        >
          <v-list-item-content>
            <v-list-item-title> Phone </v-list-item-title>
            <v-list-item-subtitle>
              {{ me.phone ? me.phone : "N/A" }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line class="pa-0">
          <v-list-item-content>
            <v-list-item-title> Last login </v-list-item-title>
            <v-list-item-subtitle>
              {{ new Date(me.lastLoginDate.slice(0, 16) + '+0000').toLocaleString('en-CA', {
                dateStyle: 'medium',
                timeStyle: 'short'
              }) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="me.client ? me.client.subscriptionSource !== 'internal' : false" two-line class="pa-0">
          <v-list-item-content>
            <v-list-item-title>Subscription</v-list-item-title>
            <v-list-item-subtitle class="text-capitalize">
              <v-icon v-if="me.client ? me.client.subscriptionSource === 'azure' : ''" class="mr-2">mdi-microsoft-azure</v-icon>
              {{ me.client ? me.client.subscriptionSource : "N/A" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
  export default {
    name: "UserDrawer",
    components: {},
    props: ["me"],
    data: () => ({
      themeSelect: false,
    }),
    methods: {
    },
  }
</script>
